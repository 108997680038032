<template>
	<div>
		<template v-if="visibleConsultants.length > 1">
			<h3 style="margin-bottom: 2em;">Where do you want to send your images?</h3>
			<consultant-cards :is-loading="isNextRouteLoading" :consultants="visibleConsultants" @select="selectConsultant" />
			<div v-if="!isCommunityUser">
				<button class="btn btn-default" style="margin-top: 1em;" @click="$router.go(-1)">
					Cancel
				</button>
			</div>
		</template>
		<div v-if="!visibleConsultants.length">
			<h3>
				Your account does not have any consultants.
			</h3>
			<p style="padding-top: 1em;">
				<template v-if="consultants.length">
					<svg-icon icon="info-circle" class="is-info" />
					Some consultants have been hidden in your
					<a class="is-link" @click.prevent="openConsultantListDlg">settings.</a>
				</template>
				<template v-else>
					A consultant must provide you with a link to add them to your account.
				</template>
			</p>
			<div>
				<button class="btn btn-default" style="margin-top: 1em;" @click="$router.go(-1)">
					Go Back
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import store from '@store'
import { mapState, mapGetters } from 'vuex'
import ConsultantCards from '@components/ConsultantCards.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { openConsultantListDlg } from '@dialogs/ConsultantList.vue'
import { workflow } from '@services/workflow'
import { salesService } from '@services/salesService'
import { uploadData } from '@services/uploads'

export default {
	name: 'TeleconsultationRequestConsultants',
	components: {
		ConsultantCards,
	},
	props: {
		ids: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			workflow: workflow,
		}
	},
	computed: {
		...mapGetters(['isCommunityUser']),
		...mapState({
			activeClinicCode: state => state.auth.claims.activeClinicCode,
			consultants: state => state.static.consultants,
		}),
		isNextRouteLoading() {
			return this.workflow.isLoading
		},
		visibleConsultants() {
			return this.consultants.filter(consultant => !consultant.isHidden)
		},
	},
	watch: {
		visibleConsultants: {
			handler(consultants) {
				if (consultants && consultants.length === 1) this.selectConsultant(consultants[0], true)
			},
			immediate: true,
		},
	},
	async beforeRouteEnter(to, from, next) {
		if (!from.path.includes('teleconsultation-request')) return next()
		const isRequestInProgress =
			uploadData.haveUploadsForContext('teleconsultation-request') ||
			(from.query && from.query.consultantId && from.query.studyId) ||
			salesService.sale
		if (!isRequestInProgress) return next()
		const warning = 'Are you sure you want to start over and lose your current progress?'
		if (await showConfirm(warning)) {
			next()
			salesService.reset()
			workflow.reset()
			uploadData.stopUploads({ context: 'teleconsultation-request' })
		}
		next(false)
	},
	methods: {
		openConsultantListDlg,
		selectConsultant(consultant, replaceRoute = false) {
			let nextRoute
			if (consultant.type === 'Repository') nextRoute = 'request-sale-hip'
			else if (this.isCommunityUser) nextRoute = 'request-upload-consultation'
			else if (consultant.type === 'ImageOnly') nextRoute = 'request-submit-image-only'
			else nextRoute = 'request-submit-report'
			const routerMethod = replaceRoute ? 'replace' : 'push'
			this.$router[routerMethod]({
				name: nextRoute,
				query: {
					...this.$route.query,
					consultantId: consultant.id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.teleconsultation-request {
	position: relative;
	height: 100%;
}
.back-button {
	margin-bottom: 1em;
}
.request {
	padding-top: 1em;
}
.out-of-office {
	font-style: italic;
	margin-top: -0.5em;
}
.input-group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 1em;
	label {
		font-weight: 400;
		min-width: 120px;
		padding-right: 1em;
	}
	.input {
		width: auto;
		min-width: 200px;
	}
}
.email-card {
	p {
		padding-bottom: 1em;
	}
}
.submit-button {
	p {
		padding-bottom: 1em;
		font-weight: 500;
	}
	text-align: center;
	margin: 3em;
}
</style>
